<template lang="pug">
head-meta(
  title-page="Весь ассортимент шоколадных изделий — собственное производство Музея «Шоколадный Выборг»е",
  description="Купить Весь ассортимент шоколадных изделий ручной работы. Корпоративные подарки, индивидуальные конфеты под заказ, необычный шоколад. Приходите пробовать шоколад!"
)
catalog-head
tile-line(
    v-for="(category, i) in catalog",
    :key="i",
    :title="category.name",
    :products="category.products",
    :route="{ name: 'Category', params: { slug: category.slug } }",
    class-mod="catalog"
  )
informer-order(class-mod="catalog")
informer-clients
contacts-line
</template>

<script>
import TileLine from '../../components/_tiles/TileLine'
import CatalogHead from '../../components/catalog/CatalogHead'
import { getProductsCatalog } from '../../assets/scripts/API'
import HeadMeta from '../../components/_layout/HeadMeta'
import Storage from '../../assets/scripts/Storage'
import ContactsLine from '../../components/_layout/TheContactsLine'
import InformerClients from '../../components/_informers/InformerClients'
import InformerOrder from '../../components/_informers/InformerOrder'

export default {
  components: { ContactsLine, HeadMeta, CatalogHead, TileLine, InformerClients, InformerOrder },
  name: 'CatalogIndexPage',
  data () {
    return {
      catalog: [],
      user: null
    }
  },
  methods: {
    getDataPage () {
      this.user = Storage.user
      const isAdult = this.user ? this.user.is_adult : false
      getProductsCatalog(isAdult).then(data => { this.catalog = data })
    },
    refreshCatalog () {
      Storage.clearCatalog()
      this.getDataPage()
    }
  },
  mounted () {
    window.addEventListener('storageRefresh', this.refreshCatalog)
    this.getDataPage()
  },
  deactivated () {
    window.removeEventListener('storageRefresh', this.refreshCatalog)
  }
}
</script>
